<template>
  <div class="pad-home">
    <HeaderBar
      title="我的订单"
      :showLeft="true"
    ></HeaderBar>
    <div class="order">
      <div class="order-tabs">
        <van-tabs
          v-model="active"
          type="line"
          title-inactive-color="#999999"
          title-active-color="#0CAA32"
          color="#0CAA32"
          :line-width="30"
          :line-height="2"
          swipeable
          :border="false"
          @change="change"
        >
          <van-tab
            v-for="(aitem,index) in orders"
            :title="aitem.title"
            :key="index"
          >
            <div class="order-list">
              <van-list
                v-model="loading"
                :finished="finished"
                finished-text="没有更多数据了~"
                @load="onLoad"
              >
                <div
                  class="order-list-item"
                  v-for="item in list"
                  :key="item.ID"
                  @click="toWait(item)"
                >
                  <div class="order-type">
                    <div :class="['order-type-title',item.OrderType=='1'?'order-type-trip':item.OrderType=='2'?'order-type-designated':item.OrderType=='3'?'order-type-errand':'order-type-purchase']">{{item.OrderType=='1'?'专车':item.OrderType=='2'?'代驾':item.OrderType=='3'?'取送件':'代购'}}</div>
                    <div :class="[item.IsAssign=='1'?'order-type-inTimeTag':'order-type-appointTag']">{{item.IsAssign=='1'?'即时订单':'预约订单'}}</div>
                    <div class="order-type-status">
                      <span
                        v-if="item.State<6"
                        class="order-type-running"
                      >进行中</span>
                      <span v-if="item.State==6">已完成</span>
                      <span v-if="item.State==7">已取消</span>
                      <span v-if="item.State==8">已过期</span>
                      <span v-if="item.State==9">已拒接</span>
                      <i :class="[item.State<6?'order-type-icongreen':'order-type-icon']"></i>
                    </div>
                  </div>

                  <div class="order-info">
                    <div class="order-info-detail">
                      <div class="order-info-detail-date">{{item.NewAppointmentTime}}</div>
                      <div class="order-info-detail-start">{{item.StratAddress}}</div>
                      <div
                        class="order-info-detail-start"
                        v-for="(items,inds) in item.AfterAddress"
                        :key="inds"
                      >{{items.city}}</div>
                      <div class="order-info-detail-end">{{item.EndAddress}}</div>
                    </div>
                    <div class="order-info-sum">
                      <div class="order-info-sum-dollar">${{item.PayAmount-item.Discount}}</div>
                      <div class="order-info-sum-cny">(¥{{Math.floor((item.PayAmount-item.Discount)*rateNum)}})</div>
                    </div>
                  </div>
                </div>
              </van-list>
            </div>
          </van-tab>
        </van-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import HeaderBar from "@/components/headerbar/headerbar";
import { Tab, Tabs, List, Toast } from "vant";
import { getOrderList } from "@/service/";
import { getStore } from "../../lib/storage.js";
// import { exchangeRate } from '@/mixins/exchangeRate.js'
import { mapState, mapActions } from "vuex";
import { getFormat } from "../../lib/units";

export default {
  name: "order",
  // mixins: [exchangeRate],
  components: {
    HeaderBar,
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    [List.name]: List,
  },
  data() {
    return {
      orders: [
        {
          type: "1",
          title: "出行",
          orderType: "1",
          orderList: [],
        },
        {
          type: "2",
          title: "代驾",
          orderType: "2",
          orderList: [],
        },
        {
          type: "3",
          title: "跑腿",
          orderType: "3",
          orderList: [],
        },
      ],
      active: 0,
      list: [],
      loading: false,
      finished: false,
    };
  },
  computed: {
    ...mapState({
      rateNum: ({ globalVal }) => globalVal.rateNum,
    }),
    activeType() {
      return this.active + 1;
    },
    umid() {
      return JSON.parse(getStore("userInfo")).ID;
    },
  },
  methods: {
    ...mapActions(["exchangeRateHandler"]),
    change(index, title) {
      let _this = this;
      Toast.loading({
        message: "加载中...",
        forbidClick: true,
      });
      getOrderList(`${this.umid}`, `${this.activeType}`).then((res) => {
        this.finished = true;
        console.log("orderres:", res);
        this.list = res.data.map((x) => {
          if (x.AfterAddress && x.AfterAddress.length) {
            // x.AfterAddress = JSON.parse(x.AfterAddress);
            try {
              x.AfterAddress = JSON.parse(x.AfterAddress);
            } catch (err) {
              if (x.AfterAddress.includes('"s')) {
                x.AfterAddress = x.AfterAddress.replace('"s', "'s");
                x.AfterAddress = JSON.parse(x.AfterAddress);
              }
            }
          }
          x.AppointmentTime = getFormat(x.AppointmentTime);
          x.NewAppointmentTime = getFormat(x.NewAppointmentTime);
          return x;
        });
        Toast.clear();
        this.loading = false;
      });
    },
    toWait(item) {
      localStorage.setItem("orderTypeactive", item.OrderType);
      this.$router.push(
        `/wait/${item.ID}/${item.OrderType}/${item.IsAssign}/${item.State}`
      );
    },
    onLoad() {
      if (localStorage.getItem("orderTypeactive")) {
        localStorage.removeItem("orderTypeactive");
      }
      setTimeout(() => {
        // 加载状态结束
        this.loading = true;
        Toast.loading({
          message: "加载中...",
          forbidClick: true,
        });
        getOrderList(`${this.umid}`, `${this.activeType}`).then((res) => {
          this.loading = false;
          this.finished = true;
          if (res) {
            // console.log("orderlist：", res);
            this.list = res.data.map((x) => {
              if (x.AfterAddress && x.AfterAddress.length) {
                if (x.AfterAddress) {
                  console.log("x.AfterAddress:", x.AfterAddress);
                  // x.AfterAddress = JSON.parse(x.AfterAddress);
                  try {
                    x.AfterAddress = JSON.parse(x.AfterAddress);
                  } catch (err) {
                    if (x.AfterAddress.includes('"s')) {
                      x.AfterAddress = x.AfterAddress.replace('"s', "'s");
                      x.AfterAddress = JSON.parse(x.AfterAddress);
                    }
                  }
                }
              }
              x.AppointmentTime = getFormat(x.AppointmentTime);
              x.NewAppointmentTime = getFormat(x.NewAppointmentTime);
              return x;
              // return x;
            });
          }
          Toast.clear();
        });
      }, 500);
    },
  },
  mounted() {
    this.active = localStorage.getItem("orderTypeactive")
      ? Number(localStorage.getItem("orderTypeactive")) - 1
      : this.active;
    if (!this.rateNum) {
      this.exchangeRateHandler();
    }
    // console.log(this.active)
    // console.log(this.rateNum)
  },
};
</script>

<style lang="less" scoped>
@import "../../styles/mixin.less";

.pad-home {
  padding-top: 94px;
}
.order {
  &-list {
    // height: 800px;
    overflow-y: scroll;
    // -webkit-overflow-scrolling: touch;
    padding-top: 46px;
    margin-bottom: 20px;
    &-item {
      width: 690px;
      min-height: 280px;
      margin: 0 auto 30px;
      padding: 30px;
      box-sizing: border-box;
      background: rgba(255, 255, 255, 1);
      box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.14);
      border-radius: 20px;
    }
  }

  &-type {
    display: flex;
    flex: 1;
    margin-bottom: 20px;
    justify-content: space-between;
    align-items: center;

    &-title {
      height: 44px;
      font-size: 32px;
      font-family: "PingFangSC-Medium";
      font-weight: 500;
      line-height: 44px;
    }

    &-trip {
      color: rgba(12, 170, 50, 1);
    }
    &-designated {
      color: rgba(237, 172, 65, 1);
    }
    &-errand {
      color: rgba(102, 155, 131, 1);
    }
    &-purchase {
      color: rgba(205, 138, 80, 1);
    }

    &-appointTag {
      // width: 100px;
      // height: 40px;
      padding: 6px 10px;
      margin-right: auto;
      margin-left: 30px;
      background: rgba(245, 166, 35, 0.1);
      border-radius: 4px;
      font-size: 24px;
      font-family: "PingFangSC-Medium";
      font-weight: 500;
      color: rgba(245, 166, 35, 1);
      // line-height: 28px;
      text-align: center;
      // vertical-align: middle;
    }

    &-inTimeTag {
      // width: 100px;
      // height: 40px;
      padding: 6px 10px;
      margin-right: auto;
      margin-left: 30px;
      background: rgba(12, 170, 50, 0.1);
      border-radius: 4px;
      font-size: 24px;
      font-family: "PingFangSC-Medium";
      font-weight: 500;
      color: rgba(12, 170, 50, 1);
      // line-height: 28px;
      text-align: center;
      // vertical-align: middle;
    }

    &-status {
      display: flex;
      align-items: center;
      font-size: 24px;
      font-family: "PingFangSC-Regular";
      font-weight: 400;
      color: rgba(153, 153, 153, 1);
      line-height: 34px;
    }

    &-running {
      color: rgba(12, 170, 50, 1);
    }

    &-icon {
      display: inline-block;
      .size(12px, 20px);
      margin-left: 10px;
      background: url("../../assets/back@2x.png") 0 center no-repeat;
      background-size: 100%;
    }
    &-icongreen {
      display: inline-block;
      .size(12px, 20px);
      margin-left: 10px;
      background: url("../../assets/back_green.png") 0 center no-repeat;
      background-size: 100%;
    }
  }

  &-info {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: space-between;
    // overflow: hidden;

    &-detail {
      width: 470px;
      margin-right: 50px;
      font-size: 28px;
      font-family: "PingFangSC-Regular";
      font-weight: 400;
      color: rgba(102, 102, 102, 1);
      line-height: 40px;
      box-sizing: border-box;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;

      &-date {
        padding-left: 42px;
        margin-bottom: 10px;
        background: url("../../assets/grey-clock@2x.png") 0 center no-repeat;
        background-size: 22px 22px;
        font-size: 28px;
        font-family: "PingFangSC-Regular";
        font-weight: 400;
        color: rgba(102, 102, 102, 1);
        line-height: 40px;
      }
      &-start {
        width: 100%;
        padding-left: 42px;
        margin-bottom: 10px;
        background: url("../../assets/start@2x.png") 0 center no-repeat;
        background-size: 22px 22px;
        font-size: 28px;
        font-family: "PingFangSC-Regular";
        font-weight: 400;
        color: rgba(102, 102, 102, 1);
        line-height: 40px;
        box-sizing: border-box;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
      &-end {
        width: 100%;
        padding-left: 42px;
        margin-bottom: 10px;
        background: url("../../assets/end@2x.png") 0 center no-repeat;
        background-size: 22px 22px;
        font-size: 28px;
        font-family: "PingFangSC-Regular";
        font-weight: 400;
        color: rgba(102, 102, 102, 1);
        line-height: 40px;
        box-sizing: border-box;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }

    &-sum {
      // width: 100px;
      height: 90px;
      text-align: center;

      &-dollar {
        font-size: 40px;
        font-family: "PingFangSC-Semibold";
        font-weight: 600;
        color: rgba(51, 51, 51, 1);
        line-height: 56px;
      }
      &-cny {
        font-size: 24px;
        font-family: "PingFangSC-Regular";
        font-weight: 600;
        color: rgba(153, 153, 153, 1);
        line-height: 56px;
      }
    }
  }
}
</style>
